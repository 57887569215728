<template>
  <b-modal
    v-model="show"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="$emit('hidden')"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>ANSWERS TO: {{ messageInfo.subject }}</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('hidden')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      id="crm-detailed-sales-filter"
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :no-visible-principal-filter="true"
      :top-paginate="false"
      @reload="$refs['refTableSalesDetailed'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTableSalesDetailed"
          no-border-collapse
          class="position-relative px-1"
          :class="
            isDarkSkin
              ? 'table-dark-detailed-sales'
              : 'table-light-detailed-sales'
          "
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="55vh"
          primary-key="id"
          responsive="sm"
          :items="searchAnswers"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #head(lead)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>

          <template #head(sent_date)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>

          <template #head(actions)="data">
            <div>
              {{ data.label }}
            </div>
          </template>

          <template #cell(lead)="data">
            <div class="text-left">
              <router-link
                v-if="data.item.chat.leadId"
                :to="
                  routerDashboardLeadRedirectioner(
                    moduleId,
                    data.item.chat.leadId
                  )
                "
                target="_blank"
                class="d-block text-primary"
                :class="textLink"
              >
                {{ data.item.chat.leadName | myFontCapitalize }}
              </router-link>
              <div>{{ data.item.chat.leadPhoneNumber }}</div>
            </div>
          </template>

          <template #cell(sent_date)="data">
            <div class="text-left">
              {{
                data.item.messages[data.item.messages.length - 1].creationTime
                  | myGlobalDay
              }}
            </div>
          </template>

          <template #cell(status)="data">
            <div class="text-left">
              {{ data.item.messages[data.item.messages.length - 1].subject }}
            </div>
          </template>

          <template #cell(actions)="data">
            <div>
              <!-- <tabler-icon
                :badge="data.item.chat.unreadCount"
                badge-classes="badge-important"
                icon="MessageIcon"
                size="25"
                style="margin-left: 0.4rem"
                class="cursor-pointer"
                v-b-tooltip.hover
                title="Go to Chat"
                @click="goToChat(data.item.chat)"
              /> -->
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import NestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import Fields from "@/views/commons/components/ring-central/chat-sms/data/answer-messages-fields.data.js";
import { mapMutations } from "vuex";
export default {
  props: {
    messageInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    modalTitle() {
      return this.messageInfo.topicName;
    },

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  data() {
    return {
      show: true,
      fields: Fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },

      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },

      startPage: 0,
      toPage: 0,
      isBusy: false,
    };
  },
  methods: {
    ...mapMutations({
      M_SET_LIST_ACTIVE: "SmsInboxStore/M_SET_LIST_ACTIVE",
    }),

    async searchAnswers() {
      const params = {
        messageId: this.messageInfo.id,
        page: this.paginate.currentPage,
        perPage: this.paginate.perPage * 1,
      };
      const { data } = await NestService.getAnswerByMessageId(params);

      this.startPage = data.from;
      this.totalRows = data.total;
      this.toPage = data.to;

      return data.data || [];
    },

    goToChat(chat) {
      this.M_SET_LIST_ACTIVE(false);
      // this.M_SET_CURRENT_CHAT(chat);
      this.$emit("hidden");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>