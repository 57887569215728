export default [
    {
        key: "lead",
        label: "lead",
        visible: true,
    },

    {
        key: "sent_date",
        label: "sent date",
        visible: true,
    },

    {
        key: "status",
        label: "subject",
        visible: true,
    },

    // {
    //     key: "actions",
    //     label: "read by",
    //     visible: true,
    // },

];
