<template>
  <div @scroll="loadMoreContacts" class="container-contatcs-palist">
    <div class="container-contacts">
      <div class="search-chat-top py-2">
        <div class="search-new-contact">
          <b-form-input
            type="text"
            class="custom-input"
            v-model="searchTxt"
            @keydown.enter="getFilterMessages"
            placeholder="Search List"
          />
        </div>
        <div class="container-filters mt-1">
          <div class="filter-v2" @click="changeTabFilter('INTERACTED')">
            ALL
          </div>
          <div
            class="filter-v2"
            @click="changeTabFilter('unread')"
            style="position: relative"
          >
            UNREAD
            <span
              class="badge badge-up badge-pill badge-important cursor-pointer"
              v-if="G_COUNTER_MESSAGES_NOT_READ_PHONE != 0"
            >
              {{ G_COUNTER_MESSAGES_NOT_READ_PHONE }}</span
            >
          </div>
          <div class="filter-v2 active-filter-v2" @click="activeStatusTab()">
            LISTS
          </div>
        </div>

        <div class="container-filters mt-1">
          <div
            class="filter-v2"
            @click="changeStatusList('active')"
            :class="G_LIST_TAB == 'active' ? 'active-filter-v2' : ''"
          >
            ACTIVE
          </div>
          <div
            class="filter-v2"
            @click="changeStatusList('deleted')"
            :class="G_LIST_TAB == 'deleted' ? 'active-filter-v2' : ''"
          >
            DELETED
          </div>
        </div>
        <!-- <div class="my-1">
          <b-button block variant="primary" @click="openModalCreateChat()">
            + NEW LIST
          </b-button>
        </div> -->
      </div>

      <div>
        <div
          class="contact"
          v-for="item in topicsByCredential"
          :key="`list_sms_${item.id}`"
          @click="setCurrentChat(item)"
          :class="setActiveStatus(item)"
        >
          <div class="d-flex align-items-center w-100">
            <div class="w-100">
              <b-row>
                <b-col cols="9">
                  <div
                    class="lead-name w-100 text-truncate"
                    style="margin-bottom: 5px"
                  >
                    {{ item.name }}
                  </div>

                  <div v-if="item.subject">
                    <div>
                      <div class="w-100 text-truncate">
                        {{ item.subject }}
                      </div>

                      <!-- <div
                        v-if="item.lastMessage.attachments.length > 0"
                        class="d-flex align-items-center"
                      >
                        <feather-icon
                          v-if="
                            item.lastMessage.attachments[0].contentType.startsWith(
                              'image/'
                            )
                          "
                          icon="ImageIcon"
                        ></feather-icon>
                        <span style="margin-left: 4px" class="text-truncate">
                          Attachment
                        </span>
                      </div> -->
                    </div>

                    <!-- <div v-if="item.lastMessage.type == 'Fax'">
                      <feather-icon icon="FileIcon" />
                      <span style="margin-left: 4px">
                        {{
                          item.lastMessage.attachments[0].id +
                          "." +
                          item.lastMessage.attachments[0].contentType.split(
                            "/"
                          )[1]
                        }}
                      </span>
                    </div> -->
                  </div>

                  <div v-else class="rc-text-muted">
                    Conversation not started
                  </div>
                </b-col>
                <b-col cols="3" style="padding-left: 0 !important">
                  <div class="text-end">
                    <div>{{ item.sent_date | myGlobal }}</div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div
          class="text-center border-top pt-1"
          v-if="topicsByCredential.length == 0"
        >
          No results found.
        </div>
      </div>
    </div>

    <rc-create-list
      v-if="showModalCreateList"
      :credential="credential"
      @onlyClose="showModalCreateList = false"
    />
  </div>
</template>

<script>
import RcCreateList from "@/views/commons/components/ring-central/chat-sms/components/RcChatCreateList.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";

export default {
  props: {
    credential: {
      type: Object,
      required: true,
    },
  },

  components: {
    RcCreateList,
  },
  data() {
    return {
      searchTxt: null,
      loadingMessages: false,
      showModalCreateList: false,
      contactsForCredentialMore: true,
      contactsForCredentiaPage: 1,
    };
  },

  computed: {
    ...mapGetters({
      G_ALL_TOPICS_BY_NUMBER: "SmsInboxStore/G_ALL_TOPICS_BY_NUMBER",
      G_CURRENT_TOPIC: "SmsInboxStore/G_CURRENT_TOPIC",
      currentUser: "auth/currentUser",
      G_COUNTER_MESSAGES_NOT_READ_PHONE:
        "SmsInboxStore/G_COUNTER_MESSAGES_NOT_READ_PHONE",
      G_LIST_ACTIVE: "SmsInboxStore/G_LIST_ACTIVE",
      G_LIST_TAB: "SmsInboxStore/G_LIST_TAB",
    }),

    topicsByCredential() {
      return this.G_ALL_TOPICS_BY_NUMBER;
    },

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  methods: {
    ...mapMutations({
      M_SET_ALL_TOPICS_BY_NUMBER: "SmsInboxStore/M_SET_ALL_TOPICS_BY_NUMBER",
      M_SET_ADD_CONTACT: "SmsInboxStore/M_SET_ADD_CONTACT",
      M_SET_CURRENT_TOPIC: "SmsInboxStore/M_SET_CURRENT_TOPIC",
      M_SET_CURRENT_TAB_CONTACTS: "SmsInboxStore/M_SET_CURRENT_TAB_CONTACTS",
      M_SET_LIST_ACTIVE: "SmsInboxStore/M_SET_LIST_ACTIVE",
      M_SET_LIST_TAB: "SmsInboxStore/M_SET_LIST_TAB"
    }),

    ...mapActions({
      A_GET_COUNTER_UNREAD_MESSAGES:
        "SmsInboxStore/A_GET_COUNTER_UNREAD_MESSAGES",
    }),

    changeStatusList(status) {
      this.M_SET_LIST_TAB(status);
      this.M_SET_CURRENT_TOPIC(null);
      this.resetAll();
    },

    changeTabFilter(tab) {
      this.M_SET_LIST_ACTIVE(false);
      this.M_SET_CURRENT_TAB_CONTACTS(tab);
    },

    async getChats() {
      if (this.loadingMessages || !this.contactsForCredentialMore) return;

      try {
        this.loadingMessages = true;
        this.addPreloader();

        const params = {
          credential_id: this.credential.id,
          page: this.contactsForCredentiaPage,
          status_list: this.G_LIST_TAB,
          search_txt: this.searchTxt,
          module_id: this.moduleId,
        };

        const { data } = await SmsService.searchTopic(params);

        if (data.length == 0) {
          this.contactsForCredentialMore = false;
        }
        const auxArray = [...this.G_ALL_TOPICS_BY_NUMBER];
        const newContacts = [...auxArray, ...data];

        this.contactsForCredentiaPage++;

        this.M_SET_ALL_TOPICS_BY_NUMBER(newContacts);
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
        this.loadingMessages = false;
      }
    },

    loadMoreContacts() {
      const contactList = this.$el;
      if (
        contactList.scrollTop +
          contactList.clientHeight +
          contactList.scrollHeight * 0.02 >=
          contactList.scrollHeight &&
        this.contactsForCredentialMore
      ) {
        this.getChats();
      }
    },

    openModalCreateChat() {
      this.showModalCreateList = true;
    },

    setCurrentChat(chat) {
      this.M_SET_CURRENT_TOPIC(chat);
    },

    getFilterMessages() {
      if (!this.searchTxt) return;

      const trimmedSearchTxt = this.searchTxt.trim();
      if (trimmedSearchTxt === "") return;

      this.newSearchTxt = this.searchTxt;
      this.contactsForCredentialMore = true;
      this.contactsForCredentiaPage = 1;
      this.M_SET_ALL_TOPICS_BY_NUMBER([]);
      this.getChats();
    },

    setActiveStatus(topic) {
      if (this.G_CURRENT_TOPIC && this.G_CURRENT_TOPIC.id == topic.id) {
        return "active";
      }
      return "";
    },

    resetAll() {
      this.contactsForCredentialMore = true;
      this.contactsForCredentiaPage = 1;
      this.M_SET_ADD_CONTACT([]);
      this.M_SET_ALL_TOPICS_BY_NUMBER([]);
      this.getChats();
    },
  },

  watch: {
    credential(value) {
      this.contactsForCredentialMore = true;
      this.contactsForCredentiaPage = 1;
      this.M_SET_ADD_CONTACT([]);
      this.M_SET_ALL_TOPICS_BY_NUMBER([]);
      this.M_SET_CURRENT_TOPIC(null);

      if (!value) return;

      this.A_GET_COUNTER_UNREAD_MESSAGES(value.id);
      this.getChats();
    },

    searchTxt(value) {
      if (value == "" && !value) {
        this.resetAll();
      }
    },
  },

  mounted() {
    this.getChats();
  },

  beforeDestroy() {
    this.M_SET_ALL_TOPICS_BY_NUMBER([]);
    this.M_SET_CURRENT_TOPIC(null);
  },
};
</script>

<style lang="scss">
.container-contatcs-palist {
  background-color: #ebebeb;
  height: inherit;
  overflow-y: auto;

  .container-contacts {
    font-family: "Rubick" !important;

    .search-chat-top {
      position: sticky;
      top: 0;
      background-color: #ebebeb;
      z-index: 10;
    }

    .search-new-contact {
      display: flex;
      align-items: center;
      height: 45px;

      .custom-input {
        height: 100%;
        background-color: #f0f2f5;
      }
    }

    .container-filters {
      border-radius: 10px;
      margin-top: 1rem;
      display: flex;
      // align-items: center;
      // justify-content: center;
      position: relative;

      .filter-v2 {
        margin: 0 8px;
        cursor: pointer;
        padding: 5px 8px;
        border-radius: 10px;
        text-align: center;
      }

      .active-filter-v2 {
        color: #fff;
        background-color: #0090e7;
      }
    }

    .contact {
      cursor: pointer !important;
      height: 80px;
      width: 100%;
      background-color: #ebebeb;
      display: flex;
      align-items: center;
      border-top: 1px solid #e3e8e7;

      .text-end {
        text-align: end;
      }
    }

    .contact:hover {
      background-color: #f0faff;
    }

    .active {
      background-color: #f0faff;
      border-left: 8px solid #0099dd;
    }
  }
}

.dark-layout {
  .container-contatcs-palist {
    background-color: #111b21;

    .container-contacts {
      .search-chat-top {
        background-color: #111b21;
      }

      .active {
        background-color: #202c33 !important;
      }

      .contact {
        background-color: #111b21;
        border-top: 1px solid #202c33;
      }

      .contact:hover {
        background-color: #202c33;
      }

      .search-new-contact {
        .custom-input {
          background-color: #202c33;
        }
      }
    }
  }
}

.container-contatcs-palist {
  @media (min-width: 1651px) {
    .search-chat-top {
      padding: 0 2rem;
    }

    .contact {
      padding: 1rem 2rem;
      font-size: 13.5px;

      .text-end {
        font-size: 11px;
      }
    }
  }

  @media (min-width: 1201px) and (max-width: 1650px) {
    .search-chat-top {
      padding: 0 1.6rem;
    }

    .contact {
      padding: 1rem 1.6rem;
      font-size: 12.5px;

      .text-end {
        font-size: 10.5px;
      }
    }
  }

  @media (max-width: 1200px) {
    .search-chat-top {
      padding: 0 1.4rem;
    }

    .contact {
      padding: 1rem 1.2rem;
      font-size: 11.5px;

      .text-end {
        font-size: 9.5px;
      }
    }
  }
}
</style>