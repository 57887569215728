<template>
  <div>
    <header-sidebar v-model="credential" @setNewChat="setNewChat" />

    <div class="custom-chat-rc">
      <b-row style="margin: 0 !important" class="h-100">
        <b-col cols="3" style="padding: 0 !important; height: inherit">
          <rc-chat-list
            v-if="G_LIST_ACTIVE && credential"
            :credential="credential"
          />
          <rc-chat-sidebar
            v-if="credential && !G_LIST_ACTIVE"
            v-model="currentChat"
            :credential="credential"
            @messageFind="findMessageById"
          />
        </b-col>
        <b-col
          :cols="
            activeSearchTextChat ||
            activeChatInfo ||
            activeChatInfoHighlight ||
            activeListInfo
              ? '6'
              : '9'
          "
          style="padding: 0 !important; height: inherit"
        >
          <div style="padding: 0 !important; height: inherit; overflow: hidden">
            <content-header
              v-if="currentChat && !G_LIST_ACTIVE"
              :current-chat="currentChat"
              @activeSearch="activeMenuRight('search')"
              @activeChatInfo="activeMenuRight('info')"
            />

            <rc-topic-header
              v-if="G_CURRENT_TOPIC && G_LIST_ACTIVE"
              @activeInfo="activeMenuRight('list')"
            />

            <rc-chat-content
              v-if="credential && currentChat && !G_LIST_ACTIVE"
              :message-id="messageFind"
              :search-txt="searchTxt"
              :credential="credential"
              :current-chat="currentChat"
              @filesUploadSize="setFilesSize"
            />

            <rc-topic-messages v-show="G_CURRENT_TOPIC && G_LIST_ACTIVE" />

            <rc-chat-welcome
              v-show="!currentChat && !G_CURRENT_TOPIC"
              :credential="credential"
            />
          </div>
        </b-col>

        <transition name="slide">
          <b-col
            cols="3"
            style="padding: 0 !important; height: inherit"
            v-if="activeSearchTextChat"
          >
            <div class="header-rc-chat-search-message">
              <div class="container-info">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('search')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">Search Message</span>
              </div>
            </div>

            <rc-chat-filter-message
              v-model="messageFind"
              :credential="credential"
              :current-chat="currentChat"
            />
          </b-col>
        </transition>

        <transition name="slide">
          <b-col
            cols="3"
            style="padding: 0 !important; height: inherit"
            v-if="activeChatInfo"
          >
            <div class="header-rc-chat-search-message new-border-2">
              <div class="container-info">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('info')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">Chat Info</span>
              </div>
            </div>

            <rc-chat-info
              :current-chat="currentChat"
              @openMessageHighlight="activeMenuRight('highlight')"
            />
          </b-col>
        </transition>

        <transition name="slide">
          <b-col
            cols="3"
            style="padding: 0 !important; height: inherit"
            v-if="activeChatInfoHighlight"
          >
            <div class="header-rc-chat-search-message new-border-2">
              <div class="container-info">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('info')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">Marked Messages</span>
              </div>
            </div>

            <rc-messages-highlight
              class="not-overflow"
              v-model="messageFind"
              :current-chat="currentChat"
            />
          </b-col>
        </transition>

        <transition name="slide">
          <b-col
            cols="3"
            style="padding: 0 !important; height: inherit"
            v-if="activeListInfo"
          >
            <div class="header-rc-chat-search-message new-border-2">
              <div class="container-info">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('list')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">List Info</span>
              </div>
            </div>

            <rc-chat-list-info />
          </b-col>
        </transition>
      </b-row>
    </div>
  </div>
</template>

<script>
import HeaderSidebar from "@/views/commons/components/ring-central/chat-sms/components/RcChatHeaderSidebar.vue";

import ContentHeader from "@/views/commons/components/ring-central/chat-sms/components/RcChatHeaderContent.vue";
import RcChatSidebar from "@/views/commons/components/ring-central/chat-sms/components/RcChatSidebar.vue";
import RcChatContent from "@/views/commons/components/ring-central/chat-sms/components/RcChatContent.vue";
import RcChatFilterMessage from "@/views/commons/components/ring-central/chat-sms/components/RcChatFilterMessageChat.vue";
import RcChatWelcome from "@/views/commons/components/ring-central/chat-sms/components/RcChatWelcome.vue";
import RcChatInfo from "@/views/commons/components/ring-central/chat-sms/components/RcChatInfo.vue";
import RcMessagesHighlight from "@/views/commons/components/ring-central/chat-sms/components/RcChatMessagesHigh.vue";
import RcChatList from "@/views/commons/components/ring-central/chat-sms/components/RcChatList.vue";
import RcTopicMessages from "@/views/commons/components/ring-central/chat-sms/components/RcTopicMessages.vue";
import RcTopicHeader from "@/views/commons/components/ring-central/chat-sms/components/RcTopicHeader.vue";
import RcChatListInfo from "@/views/commons/components/ring-central/chat-sms/components/RcChatListInfo.vue";

import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  components: {
    RcChatSidebar,
    RcChatContent,
    RcChatFilterMessage,
    ContentHeader,
    HeaderSidebar,
    RcChatWelcome,
    RcChatInfo,
    RcMessagesHighlight,
    RcChatList,
    RcTopicMessages,
    RcTopicHeader,
    RcChatListInfo,
  },

  data() {
    return {
      activeSearchTextChat: false,
      activeChatInfo: false,
      activeChatInfoHighlight: false,
      activeListInfo: false,

      messageFind: null,
      searchTxt: null,

      filesUpload: 0,

      credential: null,
      currentChat: null,
    };
  },

  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      G_LIST_ACTIVE: "SmsInboxStore/G_LIST_ACTIVE",
      G_CURRENT_TOPIC: "SmsInboxStore/G_CURRENT_TOPIC",
    }),
  },

  methods: {
    ...mapMutations({
      HIDE_ICON_SMS: "SmsInboxStore/M_SET_SHOW_ICON",
    }),

    ...mapActions({
      A_GET_USERS_IMAGES: "SmsInboxStore/A_GET_USERS_IMAGES",
    }),

    setNewChat(infoChat) {
      this.currentChat = infoChat;
    },

    activeMenuRight(menu) {
      if (this.filesUpload > 0) return;

      switch (menu) {
        case "search":
          this.activeChatInfo = false;
          this.activeChatInfoHighlight = false;
          this.activeSearchTextChat = !this.activeSearchTextChat;
          break;
        case "info":
          this.activeSearchTextChat = false;
          this.activeChatInfoHighlight = false;
          this.activeChatInfo = !this.activeChatInfo;
          break;

        case "highlight":
          this.activeSearchTextChat = false;
          this.activeChatInfo = false;
          this.activeChatInfoHighlight = !this.activeChatInfoHighlight;
          break;

        case "list":
          this.activeListInfo = !this.activeListInfo;
          break;

        default:
          break;
      }
    },

    setFilesSize(evt) {
      this.filesUpload = evt;
    },

    closeAllRightContent() {
      this.activeSearchTextChat = false;
      this.activeChatInfo = false;
      this.activeChatInfoHighlight = false;
    },

    findMessageById(findMessage) {
      this.messageFind = findMessage.id;
      this.searchTxt = findMessage.search;
    },
  },

  watch: {
    currentChat() {
      this.closeAllRightContent();
    },

    credential() {
      this.currentChat = null;
    },

    G_LIST_ACTIVE() {
      this.activeListInfo = false;
      this.closeAllRightContent();
      this.currentChat = null;
    },

    G_CURRENT_TOPIC() {
      this.activeListInfo = false;
    },

    filesUpload() {
      this.closeAllRightContent();
    },
  },

  mounted() {
    this.HIDE_ICON_SMS(false);
    this.A_GET_USERS_IMAGES(this.moduleId);
  },

  beforeDestroy() {
    this.HIDE_ICON_SMS(true);
  },
};
</script>

<style lang="scss">
.custom-chat-rc {
  font-family: "Rubick" !important;
  height: 80vh !important;
  max-height: 80vh !important;

  .match {
    color: purple;
  }

  .not-overflow {
    overflow-y: auto;
    height: 100%;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.5s, transform 0.5s;
  }
  .slide-enter,
  .slide-leave-to {
    opacity: 0;
    // transform: translateX(20px);
  }

  .rc-text-muted {
    color: #637781;
  }

  .rc-text-success {
    color: #00a884;
  }

  .rc-color-buttons {
    background-color: #0099dd !important;
  }

  .rc-color-secondary {
    color: #afb8cf;
  }

  .rc-color-dark {
    color: #171c1b;
  }

  .size-rc {
    height: 800px;
  }
  .header-rc-chat-search-message {
    background-color: #f0faff;
    color: #465352;
    height: 8%;
  }

  .new-border-2 {
    border-bottom: 1px solid #e3e8e7;
  }
}

.dark-layout {
  .custom-chat-rc {

    .header-rc-chat-search-message {
      background-color: #202c33;
      color: #f0faff;
    }

    .new-border-2 {
      border-bottom: 1px solid #111b21;
    }

    .rc-color-dark {
      color: #ffffff;
    }
  }
}
</style>