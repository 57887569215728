 <template>
  <b-modal
    v-model="showModal"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="xmd"
    header-class="p-0"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="$emit('hidden')"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span>DETAIL OF: {{ messageInfo.subject }}</span>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('hidden')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <div class="mass-messages">
      <filter-slot
        :total-rows="totalRowsSaved"
        :paginate="paginate"
        :start-page="startPageSaved"
        :to-page="toPageSaved"
        :filter="[]"
        :filter-principal="filterPrincipalSaved"
        :doublePaginate="false"
        @reload="$refs[`messageQueueTable`].refresh()"
      >
        <template #buttons>
          <div>
            <b-button
              v-if="totalMessagesNotSent > 0 && !existsRegenerate"
              class="ml-1"
              variant="outline-warning"
              @click="resentMessagesFailed()"
            >
              ({{ totalMessagesNotSent }}) Re-Sent
            </b-button>
          </div>
        </template>

        <b-table
          :ref="`messageQueueTable`"
          slot="table"
          no-provider-filtering
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(lead)="data">
            <div>
              <router-link
                :to="
                  routerDashboardLeadRedirectioner(moduleId, data.item.lead_id)
                "
                target="_blank"
                class="d-block text-primary"
                :class="textLink"
              >
                {{
                  data.item.lead_name
                    ? data.item.lead_name
                    : data.item.nickname | myFontCapitalize
                }}
              </router-link>
              <span>
                {{ data.item.lead_number }}
              </span>
            </div>
          </template>

          <template #cell(status_queue)="data">
            <div>
              <span
                v-if="data.item.error == 0"
                :class="
                  data.item.status_queue_id === 1
                    ? 'text-info'
                    : data.item.status_queue_id === 2
                    ? 'text-warning'
                    : data.item.status_queue_id === 3
                    ? 'text-success'
                    : ''
                "
              >
                {{ data.item.status_queue }}
              </span>

              <div v-else>
                <span class="text-danger"> ERROR </span>
              </div>
            </div>
          </template>

          <template #cell(created_at)="data">
            <div>
              {{ data.item.created_at | myGlobalWithHour }}
            </div>
          </template>

          <template #cell(sent_date)="data">
            <div>
              {{ data.item.sent_at | myGlobalWithHour }}
            </div>
          </template>
        </b-table>
      </filter-slot>
    </div>

    <b-modal
      size="sm"
      v-model="showModalB"
      title="Message Schedule"
      centered
      hide-footer
      @hidden="(messageDate = null), (messageHour = null)"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="Date: " label-for="date">
                <kendo-datepicker
                  id="date"
                  v-model="messageDate"
                  v-mask="'##/##/####'"
                  :first-day-of-week="1"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                  :min="new Date()"
                  class="w-100 rounded bg-transparent k-picker-custom"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="Hour: " label-for="hour">
                <kendo-timepicker
                  v-model="messageHour"
                  :format="'HH:mm'"
                  class="w-100 rounded bg-transparent k-picker-custom"
                  style="height: 2.73rem"
                  :class="{ 'border-danger': errors[0] }"
                  placeholder="Select hour"
                  :interval="5"
                  :min="minTime"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <div class="d-flex justify-content-end mt-1">
        <b-button variant="primary" @click="saveMessageAtt">
          <feather-icon icon="CalendarIcon"></feather-icon>
          Schedule
        </b-button>
      </div>
    </b-modal>
  </b-modal>
</template>

  
  
<script>
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import Fields from "@/views/commons/components/ring-central/chat-sms/components/utils/data/message-detail-fields.data.js";
import { mapGetters } from "vuex";
export default {
  props: {
    messageInfo: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },

  data() {
    return {
      showModal: true,
      listName: "",
      fields: Fields,
      showModalB: false,
      messageDate: null,
      messageHour: null,

      filterPrincipalSaved: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Name",
        model: "",
      },
      totalRowsSaved: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      startPageSaved: 0,
      toPageSaved: 0,

      auxListLeadsDelete: [],

      isBusy: false,
      totalMessagesNotSent: 0,
      existsRegenerate: false,
      minTime: "",
    };
  },

  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          search_txt: this.filterPrincipalSaved.model,
        };

        const { data } = await SmsService.getMessagesDetail(
          this.messageInfo.id,
          params
        );
        this.startPageSaved = data.from ? data.from : 0;
        this.totalRowsSaved = data.total;
        this.toPageSaved = data.to ? data.to : 0;
        return data.data ?? [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getCounterMessagesFailed() {
      try {
        const { data } = await SmsService.getCounterMessagesFailed(
          this.messageInfo.id
        );
        this.totalMessagesNotSent = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getRegenerateExists() {
      try {
        const { data } = await SmsService.getRegenerateExists(
          this.messageInfo.id
        );

        this.existsRegenerate = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async resentMessagesFailed() {
      var date = new Date();
      var roundedMinutes = Math.ceil(date.getMinutes() / 5) * 5;
      date.setMinutes(roundedMinutes);
      date.setSeconds(0);
      date.setMilliseconds(0);

      if (date.getMinutes() === 60) {
        date.setMinutes(0);
        date.setHours(date.getHours() + 1);
      }

      const auxDate = new Date();
      if (date.getMinutes() - auxDate.getMinutes() < 3) {
        date.setMinutes(date.getMinutes() + 5);
      }

      var options = {
        timeZone: "America/Los_Angeles",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      var formatter = new Intl.DateTimeFormat("en-US", options);
      var caliTime = formatter.format(date);
      var dateString = date.toISOString().split("T")[0];
      var caliDateTimeString = `${dateString} ${caliTime}`;
      this.minTime = new Date(caliDateTimeString);

      this.showModalB = true;
    },

    async saveMessageAtt() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.value) return;

      try {
        this.addPreloader();
        const params = {
          messageId: this.messageInfo.id,
          createdBy: this.currentUser.user_id,
          date: this.messageDate,
          hour: this.messageHour,
        };
        await SmsService.regenerateMessageTopic(params);
        await this.getRegenerateExists();
        this.$emit('reload');
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
        this.showModalB = false;
      }
    },
  },

  created() {
    this.getCounterMessagesFailed();
    this.getRegenerateExists();
  },
};
</script>

<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.3rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>