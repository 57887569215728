<template>
  <div class="header-rc-chat">
    <div class="container-info flex-column">
      <div class="d-flex">
        <div class="d-flex flex-column justify-content-center">
          <div
            class="font-weight-bolder cursor-pointer"
            @click="$emit('activeInfo')"
            v-if="renameActive"
            style="padding-left: 4px"
          >
            {{ G_CURRENT_TOPIC.name }}
          </div>
          <b-form-input
            style="
              padding: 0;
              padding-left: 4px;
              height: 2rem;
              min-width: 200px;
            "
            v-else
            v-model="listName"
          ></b-form-input>
        </div>
        <div
          class="d-flex align-items-center"
          v-if="isCeo && !G_CURRENT_TOPIC.deleted_by"
        >
          <feather-icon
            v-if="renameActive"
            icon="Edit2Icon"
            class="ml-1 text-warning cursor-pointer"
            v-b-tooltip.hover
            title="Rename"
            size="20"
            @click="renameActive = false"
          ></feather-icon>
          <feather-icon
            v-if="!renameActive"
            icon="SaveIcon"
            class="ml-1 text-primary cursor-pointer"
            v-b-tooltip.hover
            title="Save"
            size="20"
            @click="renameList()"
          ></feather-icon>
          <feather-icon
            v-if="!renameActive"
            icon="XSquareIcon"
            class="text-info cursor-pointer"
            v-b-tooltip.hover
            title="Cancel"
            size="20"
            @click="renameActive = true"
          ></feather-icon>
          <feather-icon
            icon="UserPlusIcon"
            class="ml-1 text-warning cursor-pointer"
            v-b-tooltip.hover
            title="Add leads"
            @click="showModal = true"
            size="20"
          ></feather-icon>
        </div>
      </div>
      <div>
        <span>({{ G_CURRENT_TOPIC_LEADS_COUNT }}) Leads</span>
      </div>
    </div>

    <div class="container-icons pr-2">
      <div
        class="d-flex align-items-center p-0 m-0"
        style="height: auto !important"
      >
        <b-button
          variant="primary"
          class="custom-btn-v2 mr-2"
          v-b-tooltip.hover
          @click="showModalFilters = true"
          title="SHOW FILTERS"
        >
          <feather-icon icon="FilterIcon" size="18" />
        </b-button>

        <b-button
          @click="$emit('activeInfo')"
          variant="primary"
          class="custom-btn-v2"
        >
          <feather-icon
            size="18"
            class="icon-search-action"
            icon="MoreVerticalIcon"
          ></feather-icon>
        </b-button>
      </div>
    </div>

    <b-modal
      title="SEARCH LEADS"
      v-model="showModal"
      @hidden="showModal = false"
      scrollable
      size="xmd"
      hide-footer
    >
      <topic-leads
        :listId="G_CURRENT_TOPIC.id"
        :moduleId="moduleId"
        :typeList="G_CURRENT_TOPIC.type_id"
      />
    </b-modal>

    <modal-list-filters
      v-if="showModalFilters"
      :list-id="G_CURRENT_TOPIC.id"
      @hidden="showModalFilters = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import TopicLeads from "@/views/commons/components/ring-central/chat-sms/components/RcChatTopicLeads.vue";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import ModalListFilters from "@/views/commons/components/ring-central/chat-sms/components/utils/ModalFilters.vue";
export default {
  components: {
    TopicLeads,
    ModalListFilters,
  },
  data() {
    return {
      showModal: false,
      showModalFilters: false,
      renameActive: true,
      listName: null,
      // totalLeads: 0,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    ...mapGetters({
      G_CURRENT_TOPIC: "SmsInboxStore/G_CURRENT_TOPIC",
      G_CURRENT_TOPIC_LEADS_COUNT: "SmsInboxStore/G_CURRENT_TOPIC_LEADS_COUNT",
      G_CURRENT_TOPIC_MESSAGES: "SmsInboxStore/G_CURRENT_TOPIC_MESSAGES",
    }),
  },

  methods: {
    ...mapActions({
      A_GET_CURRENT_TOPIC_LEADS_COUNT:
        "SmsInboxStore/A_GET_CURRENT_TOPIC_LEADS_COUNT",
    }),

    ...mapMutations({
      M_SET_CURRENT_TOPIC: "SmsInboxStore/M_SET_CURRENT_TOPIC",
      M_UPDATE_TOPIC_BY_ID: "SmsInboxStore/M_UPDATE_TOPIC_BY_ID",
    }),
    // async getTotalLeads() {
    //   const { data } = await SmsService.getLeadsListTotal({
    //     topic_id: this.G_CURRENT_TOPIC.id,
    //   });
    //   this.totalLeads = data;
    // },

    async renameList() {
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;

      try {
        const topicId = this.G_CURRENT_TOPIC.id;
        const params = {
          name: this.listName,
        };
        const { status } = await SmsService.updateTopic(topicId, params);
        if (status == 200) {
          this.renameActive = true;
          this.showSuccessSwal();
          this.M_SET_CURRENT_TOPIC({
            ...this.G_CURRENT_TOPIC,
            name: this.listName,
          });

          this.M_UPDATE_TOPIC_BY_ID({
            topicId: topicId,
            column: "name",
            value: this.listName,
          });
        }
      } catch (error) {
        this.showErrorSwal();
      }
    },
  },

  watch: {
    G_CURRENT_TOPIC(value) {
      if (!value) return;
      this.A_GET_CURRENT_TOPIC_LEADS_COUNT({
        topic_id: value.id,
      });
      this.listName = value.name;
    },
  },

  mounted() {
    // this.getTotalLeads();
    if (this.G_CURRENT_TOPIC) {
      this.A_GET_CURRENT_TOPIC_LEADS_COUNT({
        topic_id: this.G_CURRENT_TOPIC.id,
      });
    }
    this.listName = this.G_CURRENT_TOPIC.name;
  },
};
</script>