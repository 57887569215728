<template>
  <div class="chat-container-rc2">
    <div
      class="messages-container"
      ref="chatContainer"
      @scroll="loadMoreMessages"
      v-if="filesUpload.length == 0"
    >
      <div
        v-for="(message, index) in G_CURRENT_TOPIC_MESSAGES"
        :key="`topic_message_${index}`"
        class="d-flex flex-column align-items-center"
      >
        <div class="message mt-1" :id="`message_${message.id}`">
          <div class="d-flex align-items-center mr-1">
           
            <feather-icon
              icon="TrashIcon"
              class="text-warning cursor-pointer"
              style="margin-right: 5px"
              size="18"
              v-b-tooltip.hover
              title="Delete"
              @click="deleteMessageTopic(message.id)"
              v-if="message.status == 'PENDING' && !message.deleted_at"
            ></feather-icon>

            <feather-icon
              v-if="message.status == 'PENDING' && !message.deleted_at"
              icon="CalendarIcon"
              v-b-tooltip.hover
              :title="message.status"
              class="text-info"
              size="18"
            >
            </feather-icon>
            <feather-icon
              v-if="message.status == 'IN PROGRESS' && !message.deleted_at"
              icon="LoaderIcon"
              v-b-tooltip.hover
              :title="message.status"
              class="text-warning"
              size="18"
            >
            </feather-icon>
            <feather-icon
              v-if="message.status == 'COMPLETED' && !message.deleted_at"
              icon="SendIcon"
              v-b-tooltip.hover
              :title="`${message.status} - SHOW DETAIL`"
              class="text-success cursor-pointer"
              size="18"
              @click="
                openDetailMessageQueue(
                  message.id,
                  message.subject,
                  message.sent_date,
                  message.user
                )
              "
            >
            </feather-icon>

            <tabler-icon
              v-if="message.status == 'COMPLETED'"
              icon="MessageReportIcon"
              v-b-tooltip.hover
              title="SHOW ANSWERS"
              class="cursor-pointer text-info"
              size="20"
              style="margin-left: 4px"
              @click="
                openDetailMessage(
                  message.id,
                  message.subject,
                  message.sent_date,
                  message.user
                )
              "
            ></tabler-icon>
          </div>

          <div v-if="message.id" class="message-content sent-topic">
            <div class="p-1">
              <div>
                {{ message.subject }}
              </div>

              <div class="d-flex flex-wrap">
                <div v-if="message.image_url">
                  <div class="container-img mt-1">
                    <img
                      :src="message.image_url"
                      class="custom-img-2"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!message.id">
            <div class="day">{{ message.day }}</div>
          </div>
        </div>

        <div v-if="message.id" style="margin-top: 6px">
          <b-avatar
            variant="info"
            size="16"
            :src="getImageUser(message.created_by)"
            style="maring-left: 5px"
            v-b-tooltip.hover
            :title="message.user"
          ></b-avatar>
          {{
            message.deleted_at
              ? message.deleted_at
              : message.sent_date | myGlobalDay
          }}
        </div>
      </div>
    </div>

    <div
      class="input-container"
      v-if="
        filesUpload.length == 0 &&
        isCeo &&
        G_CURRENT_TOPIC &&
        !G_CURRENT_TOPIC.deleted_by
      "
    >
      <feather-icon
        icon="PaperclipIcon"
        class="mx-1 cursor-pointer mb-1"
        size="25"
        @click="activeSearchFiles()"
      ></feather-icon>

      <input
        id="inputFiles"
        type="file"
        ref="inputFiles"
        style="display: none"
        @change="handleFile"
      />

      <div class="w-100">
        <b-form-textarea
          class="custom-form-input"
          placeholder="Type message ..."
          max-rows="3"
          v-model="newMessage"
          :state="
            newMessage != '' &&
            newMessage.length >= 1 &&
            newMessage.length <= 500
          "
        />
        <div class="d-flex justify-content-between" style="margin-top: 5px">
          <div>
            <b-row>
              <b-col sm="4">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>@1</b-input-group-prepend>
                  <b-form-input placeholder="FIRST NAME" readonly />
                </b-input-group>
              </b-col>
              <b-col sm="4">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>@2</b-input-group-prepend>
                  <b-form-input placeholder="LAST NAME" readonly />
                </b-input-group>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex align-items-center h-100">
            <span>{{ newMessage ? newMessage.length : 0 }} / 500</span>
          </div>
        </div>
      </div>
      <feather-icon
        icon="SendIcon"
        class="cursor-pointer custom-button mb-1"
        size="25"
        @click="createNewMessage(0)"
      ></feather-icon>
    </div>

    <div
      class="input-container justify-content-center"
      style="font-size: 16px; padding: 0; height: 80px"
      v-if="G_CURRENT_TOPIC && G_CURRENT_TOPIC.deleted_by"
    >
      this List was deleted by {{ G_CURRENT_TOPIC.deleted_by }} at
      {{ G_CURRENT_TOPIC.deleted_at | myGlobalDay }}
    </div>

    <div v-if="filesUpload.length > 0" class="container-upload-files">
      <feather-icon
        icon="XIcon"
        @click="filesUpload = []"
        size="25"
        class="mt-2 ml-2 cursor-pointer"
      ></feather-icon>

      <div class="size-files">{{ formatSize(totalSizeFiles) }}/1.5 MB</div>

      <div class="container-preview-file">
        <b-img
          class="h-100"
          style="max-width: 80%"
          v-if="
            filePreview.fileType && filePreview.fileType.startsWith('image/')
          "
          :src="castImage2Url(filePreview.file)"
        ></b-img>
        <div
          v-else
          class="h-100 d-flex align-items-center justify-content-center"
        >
          <feather-icon icon="FileIcon" size="150"></feather-icon>
        </div>
      </div>

      <div class="mx-5 px-5 py-2 mb-2">
        <b-form-textarea
          class="custom-form-input"
          placeholder="Type message ..."
          max-rows="2"
          v-model="newMessage"
        />
      </div>

      <div class="list-files">
        <b-row class="h-100">
          <b-col
            cols="11"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              v-for="file in filesUpload"
              :key="`file_${file.id}`"
              class="file"
              @click="filePreview = file"
              :class="filePreview.id == file.id ? 'file-active' : ''"
            >
              <div
                v-if="file.fileType && file.fileType.startsWith('image/')"
                :style="{ backgroundImage: `url(${castImage2Url(file.file)})` }"
                class="file-img"
              ></div>
              <div
                v-else
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <feather-icon icon="FileIcon" size="35"></feather-icon>
              </div>
            </div>
            <!-- <div
              class="file-upload d-flex align-items-center justify-content-center"
              @click="activeSearchFiles()"
            >
              <feather-icon icon="PlusIcon" size="25"></feather-icon>
              <input
                id="inputFiles"
                type="file"
                ref="inputFiles"
                style="display: none"
                @change="handleFile"
              />
            </div> -->
          </b-col>
          <b-col
            cols="1"
            class="h-100 d-flex align-items-center justify-content-center"
          >
            <div class="send-icon-files" @click="createNewMessage(1)">
              <feather-icon
                size="22"
                icon="SendIcon"
                class="feather-icon-file"
                @click="createNewMessage(1)"
              ></feather-icon>
              <div class="counter-file">{{ filesUpload.length }}</div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal
      size="sm"
      v-model="showModalB"
      title="Message Schedule"
      centered
      hide-footer
      @hidden="(messageDate = null), (messageHour = null)"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="Date: " label-for="date">
                <kendo-datepicker
                  id="date"
                  v-model="messageDate"
                  v-mask="'##/##/####'"
                  :first-day-of-week="1"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                  :min="new Date()"
                  class="w-100 rounded bg-transparent k-picker-custom"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group label="Hour: " label-for="hour">
                <kendo-timepicker
                  v-model="messageHour"
                  :format="'HH:mm'"
                  class="w-100 rounded bg-transparent k-picker-custom"
                  style="height: 2.73rem"
                  :class="{ 'border-danger': errors[0] }"
                  placeholder="Select hour"
                  :interval="5"
                  :min="minTime"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <div class="d-flex justify-content-end mt-1">
        <b-button variant="primary" @click="saveMessageAtt">
          <feather-icon icon="CalendarIcon"></feather-icon>
          Schedule
        </b-button>
      </div>
    </b-modal>

    <topic-detail-message
      v-if="showModalDetail"
      :messageInfo="messageInfo"
      @hidden="showModalDetail = false"
    />

    <modal-detail-messages-queue
      v-if="showModalDetailQueue"
      :messageInfo="messageInfo"
      @hidden="showModalDetailQueue = false"
      @reload="refreshMessages()"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import TopicDetailMessage from "@/views/commons/components/ring-central/chat-sms/components/RcTopicDetailMessage.vue";
import ModalDetailMessagesQueue from "@/views/commons/components/ring-central/chat-sms/components/utils/ModalDetailMessagesQueue.vue";
export default {
  components: {
    TopicDetailMessage,
    ModalDetailMessagesQueue,
  },
  data() {
    return {
      showModalDetail: null,
      showModalDetailQueue: null,

      messageInfo: {
        id: null,
        message: null,
        sent_date: null,
        user: null,
        topicName: null,
      },

      minTime: "",
      messageDate: null,
      messageHour: null,
      newMessage: "",
      showModalB: false,
      filesUpload: [],
      loadingMessageScroll: false,
      FILES_AVAILABLE: [
        "application/gzip",
        "application/rtf",
        "application/zip",
        "audio/mpeg",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "text/vcard",
        "video/mp4",
        "video/mpeg",
      ],
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_CREDENTIAL: "SmsInboxStore/G_CURRENT_CREDENTIAL",
      G_USERS_IMAGES: "SmsInboxStore/G_USERS_IMAGES",
      G_CURRENT_TOPIC: "SmsInboxStore/G_CURRENT_TOPIC",
      G_CURRENT_TOPIC_MESSAGES: "SmsInboxStore/G_CURRENT_TOPIC_MESSAGES",
      currentUser: "auth/currentUser",
    }),

    totalSizeFiles() {
      let size = 0;
      this.filesUpload.forEach((file) => {
        size += file.file.size;
      });
      return size;
    },
  },

  methods: {
    ...mapMutations({
      M_SET_CURRENT_TOPIC_MESSAGES:
        "SmsInboxStore/M_SET_CURRENT_TOPIC_MESSAGES",
    }),

    loadMoreMessages() {
      const contactList = this.$refs.chatContainer;
      if (
        contactList.scrollHeight -
          (Math.abs(contactList.scrollTop) + contactList.clientHeight) <=
          10 &&
        !this.loadingMessageScroll
      ) {
        this.getMessages();
      }
    },

    async getMessages() {
      if (!this.moreMessage || !this.G_CURRENT_TOPIC.id) return;

      try {
        this.loadingMessageScroll = true;
        this.addPreloader();

        const params = {
          topic_id: this.G_CURRENT_TOPIC.id,
          page: this.currentPage,
        };

        const { data } = await SmsService.getMessages(params);

        if (data.length == 0) {
          this.moreMessage = false;
        }

        const auxArray = [...this.G_CURRENT_TOPIC_MESSAGES];
        const newContacts = [...auxArray, ...data];

        this.M_SET_CURRENT_TOPIC_MESSAGES(newContacts);

        this.currentPage++;
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.loadingMessageScroll = false;
        this.removePreloader();
      }
    },

    getImageUser(userId) {
      const user = this.G_USERS_IMAGES.find((user) => user.id == userId);
      if (user) {
        return user.image_thumb;
      }
      return null;
    },

    castImage2Url(file) {
      return URL.createObjectURL(file);
    },

    formatSize(sizeInBytes) {
      if (sizeInBytes < 1024) {
        return sizeInBytes + " bytes";
      } else if (sizeInBytes < 1024 * 1024) {
        return (sizeInBytes / 1024).toFixed(2) + " KB";
      } else {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
      }
    },

    activeSearchFiles() {
      this.$refs["inputFiles"].click();
    },

    handleFile(event) {
      const file = event.target.files[0];

      if (!this.FILES_AVAILABLE.includes(file.type)) {
        this.showToast(
          "info",
          "top-right",
          "Oops!",
          "XIcon",
          "Format not accepted"
        );
        return;
      }
      const maxSizeInBytes = 1500000;

      if (file.size > maxSizeInBytes) {
        this.showToast("info", "top-right", "Oops!", "XIcon", "File too big");
        return;
      }

      if (this.totalSizeFiles + file.size > maxSizeInBytes) {
        this.showToast(
          "info",
          "top-right",
          "Oops!",
          "XIcon",
          "files must not exceed 1.5 MB in size"
        );
        return;
      }

      const element = {
        id: this.generateUniqueId(),
        file: file,
        name: file.name,
        fileType: file.type,
        base64: null,
      };
      this.filePreview = element;
      this.filesUpload.push(element);
    },

    createNewMessage() {
      if (
        !this.newMessage ||
        this.newMessage == "" ||
        this.newMessage.length > 500
      )
        return;

      var date = new Date();
      var roundedMinutes = Math.ceil(date.getMinutes() / 5) * 5;
      date.setMinutes(roundedMinutes);
      date.setSeconds(0);
      date.setMilliseconds(0);

      if (date.getMinutes() === 60) {
        date.setMinutes(0);
        date.setHours(date.getHours() + 1);
      }

      const auxDate = new Date();
      if (date.getMinutes() - auxDate.getMinutes() < 3) {
        date.setMinutes(date.getMinutes() + 5);
      }

      var options = {
        timeZone: "America/Los_Angeles",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };

      var formatter = new Intl.DateTimeFormat("en-US", options);
      var caliTime = formatter.format(date);
      var dateString = date.toISOString().split("T")[0];
      var caliDateTimeString = `${dateString} ${caliTime}`;
      this.minTime = new Date(caliDateTimeString);

      this.showModalB = true;
    },

    refreshMessages() {
      this.M_SET_CURRENT_TOPIC_MESSAGES([]);
      this.moreMessage = true;
      this.currentPage = 1;
      this.newMessage = "";
      this.filesUpload = [];
      this.getMessages();
    },

    async saveMessageAtt() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return false;

      try {
        this.addPreloader();

        let withImage = null;

        if (this.filesUpload.length > 0) {
          withImage = true;
          await Promise.all(
            this.filesUpload.map(async (file) => {
              file.base64 = await this.readFileAsync(file.file);
            })
          );
        }

        const params = {
          topic_id: this.G_CURRENT_TOPIC.id,
          subject: this.newMessage,
          date: this.messageDate,
          hour: this.messageHour,
          created_by: this.currentUser.user_id,
          images: this.filesUpload,
          with_image: withImage,
        };

        const { status } = await SmsService.saveMessageAtt(params);

        if (status == 200) {
          this.messageHour = null;
          this.messageDate = null;

          this.refreshMessages();
          this.showModalB = false;
        }

        this.showSuccessSwal();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openDetailMessage(id, subject, sent_date, user) {
      this.messageInfo = {
        id: id,
        subject: subject,
        sent_date: sent_date,
        user: user,
        topicName: this.G_CURRENT_TOPIC.name,
      };
      this.showModalDetail = true;
    },

    openDetailMessageQueue(id, subject, sent_date, user) {
      this.messageInfo = {
        id: id,
        subject: subject,
        sent_date: sent_date,
        user: user,
        topicName: this.G_CURRENT_TOPIC.name,
      };
      this.showModalDetailQueue = true;
    },

    async deleteMessageTopic(id) {
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.value) return;

        const { status } = await SmsService.deleteMessageTopic(id, {
          userId: this.currentUser.user_id,
        });

        this.refreshMessages();

      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
  },

  watch: {
    "G_CURRENT_TOPIC.id"(value) {
      if (value == null) return;

      this.refreshMessages();
    },
  },

  beforeDestroy() {
    this.M_SET_CURRENT_TOPIC_MESSAGES([]);
  },
};
</script>

<style lang="scss">
.chat-container-rc2 {
  .sent-topic {
    text-align: justify;
    background-color: #0099dd;
    color: #f8f9fd;
    border-radius: 10px 10px 0px 10px;
  }
}

.dark-layout {
  .chat-container-rc2 {
    .sent-topic {
      background-color: #005c4b;
      color: #f8f9fd;
    }
  }
}
</style>