<template>
  <div class="rc-chat-info">
    <div class="new-container pt-1">
      <div class="d-flex flex-column align-items-center my-1">
        <div class="title rc-color-dark text-center">
          {{ G_CURRENT_TOPIC.name }}
        </div>
        <div>Created by:</div>
        <div>
          {{ G_CURRENT_TOPIC.created_by }}
        </div>
        <div>
          {{ G_CURRENT_TOPIC.created_at | myDateGlobalWithHour }}
        </div>
      </div>

      <!-- <div class="divider-v2"></div> -->
      <!-- <div
        class="pb-1 d-flex justify-content-between cursor-pointer recent-files"
      >
        <div>
          <span class="ml-1 rc-color-dark subtitle"> Recent Files</span>
          <span class="ml-1">
            ({{ G_CURRENT_CHAT_FILES.filesCounter }} files)
          </span>
        </div>
        <div v-if="G_CURRENT_CHAT_FILES.filesCounter > 0">
          <feather-icon
            :icon="statusDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="20"
            class="text-primary"
            @click="statusDropdown = !statusDropdown"
            v-b-toggle.collapse-123456
          />
        </div>
      </div> -->
      <!-- <b-collapse id="collapse-123456">
        <div class="ml-1 pb-1">
          <div
            v-for="(file, index) in G_CURRENT_CHAT_FILES.files"
            :key="file.id"
            style="margin-bottom: 3px"
            v-if="index < 4"
          >
            <div class="d-flex justify-content-between align-items-start">
              <div>
                <feather-icon
                  icon="ImageIcon"
                  v-if="file.contentType.startsWith('image/')"
                />

                <feather-icon
                  icon="YoutubeIcon"
                  v-if="file.contentType.startsWith('video/')"
                />

                <feather-icon
                  icon="MicIcon"
                  v-if="file.contentType.startsWith('audio/')"
                />

                <feather-icon
                  icon="FileIcon"
                  v-if="
                    file.contentType.startsWith('application/') ||
                    file.contentType.startsWith('text/')
                  "
                />

                <span style="margin-left: 4px" class="text-truncate">{{
                  file.id + "." + file.contentType.split("/")[1]
                }}</span>
              </div>

              <b-dropdown
                size="md"
                variant="muted"
                toggle-class="text-decoration-none"
                no-caret
                dropleft
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    class="cursor-pointer text-light"
                  ></feather-icon>
                </template>
                <b-dropdown-item href="#" @click="downloadFileRc(file)"
                  >Download</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-collapse> -->

      <!-- <div class="divider-v2" /> -->

      <!-- <div class="pb-2">
        <div>
          <span class="ml-1 rc-color-dark subtitle"> Images </span>
          <span class="ml-1">
            ({{ G_CURRENT_CHAT_FILES.imagesCounter }} files)
          </span>
        </div>

        <div class="rc-display-images px-2">
          <div
            v-for="(item, index) in G_CURRENT_CHAT_FILES.images"
            :key="index"
            class="container-image"
            v-if="index < 2"
          >
            <img
              :src="item.route"
              loading="lazy"
              @click="currentImage = item.route"
            />
          </div>
          <div
            v-if="
              G_CURRENT_CHAT_FILES.images &&
              G_CURRENT_CHAT_FILES.images.length == 3
            "
            class="container-image"
          >
            <img
              :src="G_CURRENT_CHAT_FILES.images[2].route"
              loading="lazy"
              @click="currentImage = G_CURRENT_CHAT_FILES.images[2].route"
            />
          </div>

          <div
            v-if="
              G_CURRENT_CHAT_FILES.images &&
              G_CURRENT_CHAT_FILES.images.length > 3
            "
            class="container-image"
          >
            <img :src="G_CURRENT_CHAT_FILES.images[2].route" loading="lazy" />
            <div
              class="view-more-images"
              @click="currentImage = G_CURRENT_CHAT_FILES.images[2].route"
            >
              +{{ G_CURRENT_CHAT_FILES.images.length - 3 }}
            </div>
          </div>
        </div>
      </div> -->

      <div class="divider-v2" />
      <div class="mb-1">
        <div>
          <span class="ml-1 rc-color-dark subtitle">
            {{ G_CURRENT_TOPIC_LEADS_COUNT }} Leads
          </span>
        </div>

        <div
          class="lead-topic w-100"
          v-for="(lead, index) in leadList"
          :key="`lead_${index}`"
        >
          <div class="d-flex">
            <router-link
              :to="routerDashboardLeadRedirectioner(moduleId, lead.lead_id)"
              target="_blank"
              class="d-block"
            >
              {{ lead.lead_name ? lead.lead_name : lead.nickname }}
            </router-link>

            <span style="margin-left: 4px">{{
              lead.mobile ? lead.mobile : ""
            }}</span>

          </div>
          <feather-icon
            v-if="isCeo && !G_CURRENT_TOPIC.deleted_by"
            icon="UserXIcon"
            class="text-danger cursor-pointer"
            v-b-tooltip.hover
            title="Delete"
            @click="deleteTopicLead(lead.id)"
          />
        </div>

        <div
          class="text-center cursor-pointer"
          v-if="G_CURRENT_TOPIC_LEADS_COUNT > 10"
          @click="showDetail = true"
        >
          All ({{ G_CURRENT_TOPIC_LEADS_COUNT - leadList.length }}) More
        </div>
      </div>
      <div class="divider-v2" />

      <div class="button-block-contact" v-if="isCeo && !G_CURRENT_TOPIC.deleted_by">
        <b-button variant="outline-danger" block @click="deleteList()">
          <feather-icon icon="Trash2Icon"></feather-icon>
          Delete
        </b-button>
      </div>

      <!-- <image-viewer
        v-if="currentImage != ''"
        :objectImages="G_CURRENT_CHAT_FILES.images"
        :route="currentImage"
        @hidden="currentImage = ''"
        style="z-index: 1001 !important"
      /> -->

      <detail-leads
        :listId="G_CURRENT_TOPIC.id"
        v-if="showDetail"
        @hidden="showDetail = false"
        @updateList="updateLeadList()"
      />
    </div>
  </div>
</template>

<script>
import ShowMoreText from "@/views/commons/components/ring-central/chat-sms/components/utils/ShowMoreText.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import DetailLeads from "@/views/commons/components/ring-central/chat-sms/components/utils/ModalDetailLeadsAdd.vue";

export default {
  components: {
    ShowMoreText,
    ImageViewer,
    StatusAccount,
    DetailLeads,
  },

  data() {
    return {
      currentImage: "",
      statusDropdown: false,
      leadList: [],
      showDetail: false,
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_CREDENTIAL: "SmsInboxStore/G_CURRENT_CREDENTIAL",
      G_CURRENT_TOPIC_LEADS_COUNT: "SmsInboxStore/G_CURRENT_TOPIC_LEADS_COUNT",
      G_CURRENT_TOPIC: "SmsInboxStore/G_CURRENT_TOPIC",
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },

  methods: {
    ...mapMutations({
      M_SET_CURRENT_TOPIC: "SmsInboxStore/M_SET_CURRENT_TOPIC",
      M_SET_CURRENT_TOPIC_MESSAGES:
        "SmsInboxStore/M_SET_CURRENT_TOPIC_MESSAGES",
      M_REMOVE_TOPIC_BY_ID: "SmsInboxStore/M_REMOVE_TOPIC_BY_ID",
    }),

    ...mapActions({
      A_GET_CURRENT_TOPIC_LEADS_COUNT:
        "SmsInboxStore/A_GET_CURRENT_TOPIC_LEADS_COUNT",
    }),

    updateLeadList() {
      this.getLeadsByTopic();
      this.A_GET_CURRENT_TOPIC_LEADS_COUNT({
        topic_id: this.G_CURRENT_TOPIC.id,
      });
      this.showDetail = false;
    },

    openAllFiles() {
      if (this.G_CURRENT_CHAT_FILES.images[0]) {
        this.currentImage = this.G_CURRENT_CHAT_FILES.images[0].route;
      }
    },

    downloadFileRc(item) {
      this.forceDownloadAnyFile(
        item.route,
        `${item.id}.${item.contentType.split("/")[1]}`
      );
    },

    async deleteList() {
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;

      try {
        const topicId = this.G_CURRENT_TOPIC.id;
        const params = {
          deleted_by: this.currentUser.user_id,
        };
        const { status } = await SmsService.updateTopic(topicId, params);
        if (status == 200) {
          this.showSuccessSwal();
          this.M_SET_CURRENT_TOPIC(null);
          this.M_SET_CURRENT_TOPIC_MESSAGES([]);
          this.M_REMOVE_TOPIC_BY_ID(topicId);
        }
      } catch (error) {
        this.showErrorSwal();
      }
    },

    async getLeadsByTopic() {
      try {
        this.addPreloader();
        const params = {
          topic_id: this.G_CURRENT_TOPIC.id,
          per_page: 10,
          page: 1,
        };

        const { data } = await SmsService.getLeadsByTopic(params);
        if (data.data.length > 0) {
          this.leadList = data.data;
        }
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async deleteTopicLead(topicLeadId) {
      const confirm = await this.showConfirmSwal();
      if (!confirm) return;

      try {
        const { status } = await SmsService.deleteLeadFromTopic({
          topic_id: this.G_CURRENT_TOPIC.id,
          lead_ids: [{ id: topicLeadId }],
        });

        if (status == 200) {
          this.A_GET_CURRENT_TOPIC_LEADS_COUNT({
            topic_id: this.G_CURRENT_TOPIC.id,
          });
          this.getLeadsByTopic();
        }
      } catch (error) {
        this.showErrorSwal();
      }
    },
  },

  mounted() {
    this.getLeadsByTopic();
  },
};
</script>

<style lang="scss">
.rc-chat-info {
  .lead-topic {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
}
</style>